import React from "react"
import { Link } from 'gatsby';
import Layout from "../components/layout"
import Seo from "../components/seo"
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <div className="page-not-found__container">
      <div className="page-not-found__container--box">
        <div className="page-not-found__container--bg"><FontAwesomeIcon icon={['fas', 'globe-americas']} /></div>
        <div className="page-not-found__container--fafaicon"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /></div>
        <div className="page-not-found__container--textbox">
          <h1>Page Not Found</h1>
          <p>Oops. It looks like the page you are trying to view does not exist or has been moved.</p>
          <Link to="/">Continue to ActiveMedia Home Page</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
